export const UPDATE_SUPPLIER = `
mutation updateSupplier(
    $tenant_id: Int!,
    $input: UpdateSupplierInput!
){
  updateSupplier(
    tenant_id:$tenant_id,
    input:$input
  ){
    __typename
    ...on Supplier{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const CREATE_SUPPLIER = `
mutation createSupplier(
  $tenant_id: Int!,
  $input: CreateSupplierInput!
){
  createSupplier(
    tenant_id:$tenant_id,
    input:$input
  ){
    __typename
    ...on Supplier{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const DELETE_SUPPLIER = `
mutation deleteSupplier(
  $tenant_id: Int!,
  $id: Int!
){
  deleteSupplier(
    tenant_id:$tenant_id,
    id:$id
  ){
    __typename
    ...on Supplier{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
