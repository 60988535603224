import { useContext, useState } from 'react';
import { ContextApp } from '../contexts/ContextApp';
import {
  useCustomLazyQuery,
  useCustomMutation,
} from './apollo/ApolloCustomHooks';
import { Query } from '../services/graphql/query';
import {
  IFormCreateProduct,
  IFormUpdateProduct,
  IProduct,
} from '../interfaces/Product';
import { Mutation } from '../services/graphql/mutation';
import { useCustomMessage } from './useCustomMessage';
import { FormInstance, UploadFile } from 'antd';
import { Tools } from '../shared';
import { IFormProductVariantAttributeOptionsCombinations } from '../interfaces/ProductVariantAttributeOption';
import { EnumsValues } from '../enums/EnumsValues';

interface IUseProductArgs {
  defaultValueStateLoadingProduct?: boolean;
}
const useProduct = (args?: IUseProductArgs) => {
  const { selectedTenantId, t } = useContext(ContextApp);
  const [loadingCreateProduct, setLoadingCreateProduct] =
    useState<boolean>(false);
  const [loadingUpdateProduct, setLoadingUpdateProduct] =
    useState<boolean>(false);
  const [loadingProduct, setLoadingProduct] = useState<boolean>(
    !!args?.defaultValueStateLoadingProduct,
  );
  const [product, setProduct] = useState<IProduct>();

  const { showMessageError, messageSuccess } = useCustomMessage();

  const [createProductMutation] = useCustomMutation<{
    createProduct: IProduct;
  }>(Mutation.createProduct);

  const [updateProductMutation] = useCustomMutation<{
    updateProduct: IProduct;
  }>(Mutation.updateProduct);
  const [fetchProduct] = useCustomLazyQuery<{
    product: IProduct;
  }>(Query.product);

  const getProduct = async ({
    id,
    onErrorCallback,
  }: {
    id: number;
    onErrorCallback?: () => void;
  }) => {
    setLoadingProduct(true);
    try {
      const { data, error } = await fetchProduct({
        variables: {
          tenant_id: selectedTenantId,
          id: Number(id),
        },
      });
      if (error) throw error;
      if (data) setProduct(data.product);
    } catch (error: any) {
      const { networkError } = error;
      if (networkError) {
        showMessageError({
          context: 'useProduct.getProduct.1',
          error: networkError,
        });
      } else {
        showMessageError({
          context: 'useProduct.getProduct.2',
          error,
        });
      }
      onErrorCallback && onErrorCallback();
    }
    setLoadingProduct(false);
  };
  const createProduct = async ({
    values,
    formInstance,
    onSuccessCallback,
    onErrorCallback,
  }: {
    values: IFormCreateProduct;
    formInstance: FormInstance<any>;
    onSuccessCallback?: () => void;
    onErrorCallback?: () => void;
  }) => {
    try {
      setLoadingCreateProduct(true);
      await createProductMutation({
        variables: {
          input: {
            ...values,
            product_specifications: values.product_specifications.map(
              (ele) => ({
                ...ele,
                images: undefined,
                files: ele.images
                  ? ele.images.fileList.map((ele) => ele.originFileObj)
                  : undefined,
              }),
            ),
          },
          tenant_id: selectedTenantId,
        },
      });
      messageSuccess({
        context: 'useProduct.createProduct.1',
        message: Tools.capitalize(t('message.productCreated')),
      });
      formInstance.resetFields();
      onSuccessCallback && onSuccessCallback();
    } catch (error: any) {
      const { networkError } = error;
      if (networkError) {
        showMessageError({
          context: 'useProduct.createProduct.2',
          error: networkError,
        });
      } else {
        showMessageError({
          context: 'useProduct.createProduct.3',
          error: error,
        });
      }
      onErrorCallback && onErrorCallback();
    }
    setLoadingCreateProduct(false);
  };

  const updateProduct = async ({
    values,
    onErrorCallback,
    onSuccessCallback,
  }: {
    values: IFormUpdateProduct;
    _formInstance?: FormInstance<any>;
    onSuccessCallback?: () => void;
    onErrorCallback?: () => void;
  }) => {
    try {
      setLoadingUpdateProduct(true);
      await updateProductMutation({
        variables: {
          input: {
            id: product?.id,
            ...values,
            weight: values.weight ? Number(values.weight) : undefined,
            height: values.height ? Number(values.height) : undefined,
            width: values.width ? Number(values.width) : undefined,
            length: values.length ? Number(values.length) : undefined,
            delete_pictures: values.delete_pictures?.length
              ? values.delete_pictures
              : undefined,
            images: undefined,
          },
          tenant_id: selectedTenantId,
        },
      });
      messageSuccess({
        context: 'useProduct.updateProduct.1',
        message: Tools.capitalize(t('message.productUpdated')),
      });
      onSuccessCallback && onSuccessCallback();
    } catch (error: any) {
      const { networkError } = error;
      if (networkError) {
        showMessageError({
          context: 'useProduct.UpdateProduct.2',
          error: networkError,
        });
      } else {
        showMessageError({
          context: 'useProduct.UpdateProduct.3',
          error,
        });
      }
      onErrorCallback && onErrorCallback();
    }
    setLoadingUpdateProduct(false);
  };

  const parseVariantsProductSpecifications = async ({
    productVariantAttributesOptionsCombinations,
    values,
    editMode,
    newMultipleImages,
    multiplePicturesToDelete,
  }: {
    values: any;
    productVariantAttributesOptionsCombinations: IFormProductVariantAttributeOptionsCombinations[];
    editMode?: boolean;
    newMultipleImages?: { [key: number]: UploadFile[] };
    multiplePicturesToDelete?: { [key: number]: number[] };
  }) => {
    const results = await Promise.all(
      productVariantAttributesOptionsCombinations.map(async (ele) => {
        const newFiles = editMode
          ? newMultipleImages
            ? await Promise.all(
                newMultipleImages[ele.id]
                  .map((file) =>
                    file.thumbUrl && file.name
                      ? Tools.base64ToRcFile(file.thumbUrl, file.name)
                      : null,
                  )
                  .filter((file) => file !== null),
              )
            : undefined
          : undefined;

        const formValues = {
          cost: Number(values[`cost-id${ele.id}`]),
          suggested_price: Number(values[`suggested_price-id${ele.id}`]),
          stock: values[`stock-id${ele.id}`]
            ? Number(values[`stock-id${ele.id}`])
            : undefined,
          status: Number(values[`status-id${ele.id}`]),
          images: !editMode ? values[`images-id${ele.id}`] : undefined,
          id: values[`product_specification-id${ele.id}`],
          new_files: newFiles,
          delete_pictures: editMode
            ? multiplePicturesToDelete
              ? multiplePicturesToDelete[ele.id]
              : undefined
            : undefined,
        };

        delete values[`cost-id${ele.id}`];
        delete values[`suggested_price-id${ele.id}`];
        delete values[`stock-id${ele.id}`];
        delete values[`status-id${ele.id}`];
        delete values[`images-id${ele.id}`];
        delete values[`product_specification-id${ele.id}`];

        return {
          values: !editMode ? ele.combination : undefined,
          ...formValues,
        };
      }),
    );

    return results;
  };
  const parseSingleProductSpecifications = async ({
    values,
    editMode,
    newImages,
    picturesToDelete,
  }: {
    values: any;
    editMode?: boolean;
    newImages?: UploadFile[];
    picturesToDelete?: number[];
  }) => {
    const newFiles = editMode
      ? newImages
        ? await Promise.all(
            newImages.map((ele) =>
              ele.thumbUrl && ele.name
                ? Tools.base64ToRcFile(ele.thumbUrl, ele.name)
                : null,
            ),
          ).then((files) => files.filter((file) => file !== null)) // Filtramos los valores nulos
        : undefined
      : undefined;

    const formValues = {
      cost: Number(values['cost']),
      suggested_price: Number(values['suggested_price']),
      stock: values['stock'] ? Number(values['stock']) : undefined,
      status: Number(values['status']),
      id: values[`product_specification-id`],
      images: !editMode ? values['images'] : undefined,
      new_files: newFiles,
      delete_pictures: editMode ? picturesToDelete || undefined : undefined,
    };

    delete values['cost'];
    delete values['suggested_price'];
    delete values['stock'];
    delete values['status'];
    delete values['images'];
    delete values[`product_specification-id`];

    return [
      {
        values: !editMode ? [] : undefined,
        ...formValues,
      },
    ];
  };

  const getParsedInitialValuesToUpdate = () => {
    let initialValues: any = {};
    if (product) {
      initialValues = {
        ...product,
        status: product?.status || EnumsValues.ProductStatusId.Active,
        warranty: product?.warranty || undefined,
        is_private: product?.is_private || false,
        categories: product?.product_category?.length
          ? product.product_category.map((ele: any) => ele.category_id)
          : undefined,
      };

      if (product.has_variants) {
        if (
          product.product_specification &&
          product.product_specification.length
        ) {
          product.product_specification.forEach((ele) => {
            initialValues[`cost-id${ele.id}`] = ele.cost;
            initialValues[`suggested_price-id${ele.id}`] = ele.suggested_price;
            initialValues[`stock-id${ele.id}`] = ele.stock;
            initialValues[`status-id${ele.id}`] = ele.status;
            initialValues[`product_specification-id${ele.id}`] = ele.id;
          });
        }
      } else {
        if (
          product.product_specification &&
          product.product_specification.length
        ) {
          initialValues = {
            ...initialValues,
            ...product.product_specification[0],
            'product_specification-id': product.product_specification[0].id,
          };
        }
      }
    }
    return initialValues;
  };

  return {
    updateProduct,
    createProduct,
    getProduct,
    loadingCreateProduct,
    loadingUpdateProduct,
    loadingProduct,
    product,
    parseVariantsProductSpecifications,
    parseSingleProductSpecifications,
    getParsedInitialValuesToUpdate,
  };
};
export default useProduct;
