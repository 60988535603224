import { createRef, RefObject, useRef, useState } from 'react';
import { FormInstance } from 'antd';
import {
  IFormProductVariantAttributeOptions,
  IFormProductVariantAttributeOptionsCombinations,
  IFormProductVariantAttributeOptionsValues,
} from '../interfaces/ProductVariantAttributeOption';

const VARIANTS_DATA_DEFAULT_VALUE = [
  {
    id: -1,
    values: {
      attribute: '',
      values: [],
    },
  },
  {
    id: -2,
    values: {
      attribute: '',
      values: [],
    },
  },
];

const useProductsVariants = () => {
  const [
    variantConfigurationModalVisible,
    setVariantConfigurationModalVisible,
  ] = useState<boolean>(false);
  const productVariantAttributesOptions = useRef<
    IFormProductVariantAttributeOptions[]
  >(VARIANTS_DATA_DEFAULT_VALUE);
  const [formIds, setFormIds] = useState<number[]>([-1, -2]);
  const [
    productVariantAttributesOptionsCombinations,
    setProductVariantAttributesOptionsCombinations,
  ] = useState<IFormProductVariantAttributeOptionsCombinations[]>([]);
  const formsRefs = useRef<RefObject<{ form: FormInstance }>[]>([]);

  formsRefs.current = formIds.map(
    (_formId, index) => (formsRefs.current[index] = createRef()),
  );

  const openVariantConfigurationModalVisible = () =>
    setVariantConfigurationModalVisible(true);
  const closeVariantConfigurationModalVisible = () =>
    setVariantConfigurationModalVisible(false);

  const addForm = () => {
    setFormIds([...formIds, formIds[formIds.length - 1] - 1]);

    productVariantAttributesOptions.current.push({
      id: formIds[formIds.length - 1] - 1,
      values: {
        attribute: '',
        values: [],
      },
    });
  };

  const deleteForm = (id: number) => {
    if (formIds.length > 1) {
      setFormIds(formIds.filter((formId) => formId !== id));
      productVariantAttributesOptions.current =
        productVariantAttributesOptions.current.filter(
          (pvao) => pvao.id !== id,
        );
    }
  };

  const handleFieldChange = (formId: number, values: any) => {
    const index = productVariantAttributesOptions.current.findIndex(
      (ele) => ele.id === formId,
    );

    if (index !== -1) {
      productVariantAttributesOptions.current[index].values = values;
    }
  };

  const combineOptions = (
    options: IFormProductVariantAttributeOptionsValues[],
  ): { attribute: string; value: string }[][] => {
    if (options.length === 0) return [[]]; // Caso base: devolver una combinación vacía

    const [first, ...rest] = options; // Desestructurar el primer atributo y el resto
    const restCombinations = combineOptions(rest); // Generar combinaciones recursivamente para el resto

    // Combinar cada opción del primer atributo con las combinaciones del resto
    return first.values.flatMap((value) =>
      restCombinations.map((combo) => [
        { attribute: first.attribute, value },
        ...combo,
      ]),
    );
  };

  const getOptionsCombinations = (
    data: IFormProductVariantAttributeOptions[],
  ): IFormProductVariantAttributeOptionsCombinations[] => {
    const combinations = combineOptions(data.map((ele) => ele.values));

    return combinations.map((combination, index) => ({
      id: (index + 1) * -1,
      combination,
    }));
  };

  return {
    variantConfigurationModalVisible,
    openVariantConfigurationModalVisible,
    closeVariantConfigurationModalVisible,
    productVariantAttributesOptions,
    addForm,
    deleteForm,
    handleFieldChange,
    formIds,
    formsRefs,
    getOptionsCombinations,
    productVariantAttributesOptionsCombinations,
    setProductVariantAttributesOptionsCombinations,
  };
};
export default useProductsVariants;
