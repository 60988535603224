import './AdminInvitationPage.less';
import ProTable, { ActionType } from '@ant-design/pro-table';
import { Button, Divider, Input, Select, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import GraphqlService from '../../services/graphql/GraphqlService';
import {
  ABM,
  Authorization,
  showCollapseRender,
  Tools,
  MomentJS,
} from '../../shared';
import { ExportableColumn, ExportableTable } from '../../shared/Exporter';
import { ExporterDropdown, SaveForm } from '../../components/common/ABM';
import dayjs from 'dayjs';
import {
  LeftOutlined,
  RedoOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { IRole } from '../../interfaces';
import { FORMAT_DATE_TIME_1 } from '../../shared/MomentJS';
import { useHistory } from 'react-router';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import { useCustomMessage } from '../../hooks/useCustomMessage';
import {
  useCustomLazyQuery,
  useCustomMutation,
} from '../../hooks/apollo/ApolloCustomHooks';
import { tooltipTrigger } from '../../shared/antdUtils';
import { IInvitation } from '../../interfaces/Invitation';

const AdminInvitationPage = () => {
  const { functions, t, selectedTenantId, tenantsAssociatedToUser } =
    useContext(ContextApp);
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [searchText, setSearchText] = useState('');
  const [dataTable, setDataTable] = useState<IInvitation[]>([]);
  const [params, setParams] = useState<Record<string, any>>();
  const [dataRoles, setDataRoles] = useState<IRole[]>([]);
  const [invitationModalVisible, setInvitationModalVisible] = useState(false);
  const history = useHistory();

  const { Query, Mutation } = GraphqlService();
  const {
    messageError,
    messageDeleteSuccess,
    messageDeleting,
    messageCreateError,
    getErrorMessage,
    messageSuccess,
    messageLoading,
  } = useCustomMessage();

  const TITLE_PRO_TABLE = '';
  const TITLE_INVITATION_FORM = t('abm.titleCreateForm', {
    replace: { entity: t('entity.invitation') },
  });
  const INPUT_SEARCH_PLACEHOLDER = t('abm.inputSearchPlaceholder');
  const LIST_FILTER = ['email', 'role_id', 'accepted'];
  const LIST_SORTER = ['email', 'due_date', 'accepted'];

  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const reloadUserCombo = useRef<boolean>(true);

  const [fetchRoles] = useCustomLazyQuery<{
    roles: IRole[];
  }>(Query.roles, { variables: { tenant_id: selectedTenantId } });

  const [createInvitationMutation] = useCustomMutation<{
    createInvitation: any;
  }>(Mutation.createInvitation);

  const [deleteInvitationMutation] = useCustomMutation<{
    deleteInvitation: any;
  }>(Mutation.deleteInvitation);

  const [resendInvitationMutation] = useCustomMutation<{
    resendInvitation: any;
  }>(Mutation.resendInvitation);

  const [fetchInvitations] = useCustomLazyQuery<{
    invitations: IInvitation[];
  }>(Query.invitations, {
    variables: { ...variables.current, tenant_id: selectedTenantId },
  });

  const { mobileOnSizeChangeProTable, showComponent } = useProTableForMobile({
    layout: 'horizontal',
  });

  const getRoles = async () => {
    try {
      const tenantSelected = tenantsAssociatedToUser.find(
        (element) => element.id === selectedTenantId,
      );
      if (!tenantSelected) {
        console.error(
          'No se encontró el tenant seleccionado en el listado de tenants asociados al usuario',
        );
        return;
      }
      const role_type: number[] = [];
      if (selectedTenantId === EnumsValues.SystemTenantsIds.SystemTenant) {
        role_type.push(EnumsValues.RoleTypes.Platform);
      } else {
        if (
          tenantSelected.supplier_account &&
          tenantSelected.supplier_account.length
        ) {
          role_type.push(EnumsValues.RoleTypes.SupplierAccount);
        }
        if (
          tenantSelected.dropshipper_account &&
          tenantSelected.dropshipper_account.length
        ) {
          role_type.push(EnumsValues.RoleTypes.DropshipperAccount);
        }
      }

      const { data, error } = await fetchRoles({
        variables: {
          filter: role_type.length ? { role_type } : undefined,
        },
      });

      if (error) {
        throw error;
      }
      setDataRoles(() => data?.roles || []);
      return {
        current: 1,
        data: data?.roles,
        pageSize: '1',
        success: true,
        total: data?.roles?.length,
      };
    } catch (error) {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const createInvitation = async (value: {
    role_id: number;
    tenant_id: number;
    email: string;
  }) => {
    setFormLoading(() => true);
    messageLoading({
      context: 'adminInvitation.createInvitation.1',
      message: t('abm.message.sendingInvitation'),
    });

    try {
      await createInvitationMutation({
        variables: {
          ...value,
        },
      });
      messageSuccess({
        context: 'adminInvitation.createInvitation.2',
        message: t('abm.message.invitationSended'),
      });
      actionRef.current?.reloadAndRest?.();
      setInvitationModalVisible(false);
    } catch (error: any) {
      if (error.status_code && error.message) {
        setFormLoading(() => false);
        return messageError({
          context: 'adminInvitation.createInvitation.3',
          message: getErrorMessage(error),
        });
      }
      messageCreateError({ context: 'adminInvitation.createInvitation.4' });
    }
    setFormLoading(() => false);
  };

  const deleteInvitation = async (id: number) => {
    messageDeleting({
      context: 'adminInvitation.deleteInvitation.1',
      message: t('entity.invitation'),
    });
    try {
      await deleteInvitationMutation({
        variables: {
          id,
          tenant_id: selectedTenantId,
        },
      });
      messageDeleteSuccess({
        context: 'adminInvitation.deleteInvitation.2',
      });
      if (actionRef.current?.reset) {
        reloadUserCombo.current = true;
        actionRef.current.reset();
      }
    } catch (error: any) {
      messageError({
        context: 'adminInvitation.deleteInvitation.3',
        message: error.message,
      });
    }
    setFormLoading(() => false);
  };

  const resendInvitation = async (id: number) => {
    setFormLoading(() => true);
    messageLoading({
      context: 'adminInvitation.resendInvitation.1',
      message: t('abm.message.sendingInvitation'),
    });
    try {
      await resendInvitationMutation({
        variables: {
          id,
          tenant_id: selectedTenantId,
        },
      });
      messageSuccess({
        context: 'adminInvitation.resendInvitation.2',
        message: t('abm.message.invitationSended'),
      });
      if (actionRef.current?.reset) {
        reloadUserCombo.current = true;
        actionRef.current.reset();
      }
    } catch (error: any) {
      messageError({
        context: 'adminInvitation.resendInvitation.3',
        message: error.message,
      });
    }
    setFormLoading(() => false);
  };

  const request = async ({ sorter, filter }: any) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};

    const search: any = ABM.valuesResult({ sorter, filter, ...params });
    switch (params?.accepted) {
      case 'true':
        search.accepted = true;
        break;
      case 'false':
        search.accepted = false;
        break;
      case 'null':
        search.accepted = null;
        break;
    }
    if (searchText) {
      variables.current.searchText = searchText;
    } else {
      delete variables.current.searchText;
    }

    LIST_FILTER.forEach((element) => {
      try {
        if (search[element] !== undefined) {
          if (!variables.current.filter) {
            variables.current.filter = {};
          }
          variables.current.filter[element] = search[element];
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    LIST_SORTER.forEach((element) => {
      try {
        if (search.sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search.sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });
    try {
      const { data, error } = await fetchInvitations();

      if (error) {
        throw error;
      }
      setDataTable(() => data?.invitations || []);
      if (reloadUserCombo.current) {
        reloadUserCombo.current = false;
      }
      return {
        current: 1,
        data: data?.invitations,
        pageSize: '1',
        success: true,
        total: data?.invitations?.length,
      };
    } catch (error) {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const invitationColumns = useCallback(
    (): ExportableColumn<IInvitation>[] => [
      {
        export: false,
        dataIndex: 'tenant_id',
        title: Tools.capitalize(t('entity.ecomdropAccount')),
        renderFormItem: () => (
          <Select
            disabled
            value={selectedTenantId}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            options={tenantsAssociatedToUser.map((tenant) => ({
              label:
                tenant.id === EnumsValues.SystemTenantsIds.SystemTenant
                  ? Tools.capitalize(t('label.allAccounts'))
                  : tenant.name,
              value: tenant.id,
            }))}
            placeholder={t('abm.selectPlaceholder', {
              replace: { entity: t('entity.ecomdropAccount') },
            })}
            maxLength={200}
          />
        ),
        align: 'left',
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: false,
        dataIndex: 'email',
        title: Tools.capitalize(t('entity.email')),
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('validation.emailRequired'),
            },
            {
              type: 'email',
              message: t('validation.emailInvalid'),
            },
          ],
        },
        renderFormItem: () => (
          <Input
            placeholder={t('abm.inputPlaceholder', {
              replace: { entity: t('entity.email') },
            })}
            maxLength={200}
          />
        ),
        align: 'left',
      },
      {
        export: false,
        dataIndex: 'role_id',
        title: Tools.capitalize(t('entity.role')),
        valueType: 'select',
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('validation.roleRequired'),
            },
          ],
        },
        renderFormItem: () => (
          <Select
            options={dataRoles.map((rol) => ({
              label: rol.name,
              value: rol.id,
            }))}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placeholder={t('abm.inputPlaceholder', {
              replace: { entity: t('entity.role') },
            })}
            allowClear
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
      },
    ],
    [dataRoles],
  );

  const columns = useCallback(
    (_editMode: boolean): ExportableColumn<IInvitation>[] => [
      {
        export: false,
        dataIndex: 'idUser',
        title: 'id',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'email',
        title: Tools.capitalize(t('entity.email')),
        render: (_, record) => record.email,
        renderFormItem: () => (
          <Input
            placeholder={t('abm.inputPlaceholder', {
              replace: { entity: t('entity.email') },
            })}
            maxLength={200}
          />
        ),
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'role_id',
        title: Tools.capitalize(t('entity.role')),
        valueType: 'select',
        render: (_, record) => record.role?.name || '-',
        renderDataExport: (record: { role?: any }) => record.role.name || '-',
        renderFormItem: () => (
          <Select
            options={dataRoles.map((rol) => ({
              label: rol.name,
              value: rol.id,
            }))}
            placeholder={t('abm.inputPlaceholder', {
              replace: { entity: t('entity.role') },
            })}
            mode="multiple"
            allowClear
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'due_date',
        title: Tools.capitalize(t('entity.expirationDate')),
        render: (_, record) =>
          dayjs(record.due_date).format(FORMAT_DATE_TIME_1),
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'accepted',
        title: Tools.capitalize(t('entity.status')),
        type: ABM.TYPE_COLUMN.BOOLEAN,
        render: (_, record) => {
          switch (record.accepted) {
            case null:
              return t('abm.invitationPending');
            case true:
              return t('abm.invitationAccepted');
            case false:
              return t('abm.invitationRejected');
          }
        },
        renderFormItem: () => (
          <Select
            options={[
              { label: t('abm.invitationAccepted'), value: 'true' },
              { label: t('abm.invitationRejected'), value: 'false' },
              { label: t('abm.invitationPending'), value: 'null' },
            ]}
            allowClear
          />
        ),
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        title: 'Op.',
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.UserCreate,
            ) ? (
              <Tooltip
                key="remove_user_tooltip"
                title={t('abm.resendInvitation')}
              >
                <Button
                  className="pointer"
                  onClick={() => {
                    resendInvitation(record.id);
                  }}
                  disabled={record.accepted !== null}
                  icon={<RedoOutlined />}
                  type="text"
                />
              </Tooltip>
            ) : null}
            {Authorization.security(
              functions,
              EnumsValues.Functions.UserCreate,
            ) ? (
              <>
                <Divider type="vertical" />
                <Tooltip key="remove_user_tooltip" trigger={tooltipTrigger}>
                  <DeleteOutlined
                    className="pointer"
                    onClick={() => {
                      deleteInvitation(record.id);
                    }}
                  />
                </Tooltip>
              </>
            ) : null}
          </>
        ),
      },
    ],
    [dataRoles, resendInvitation, deleteInvitation, functions],
  );

  let LIST_FILTER_NAMES = columns(false)
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
      return false;
    })
    .map((element) => {
      return element.title;
    });

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const exportableTable: ExportableTable<IInvitation> = {
    columns: columns(false),
    items: dataTable,
    filename: `user_${MomentJS.momentDefaultFormat()}`,
  };

  useEffect(() => {
    actionRef.current?.reloadAndRest?.();
  }, [params]);

  return (
    <>
      <div className="invitation-container">
        <button
          className="go-back"
          onClick={() => {
            history.goBack();
          }}
        >
          {<LeftOutlined />}
          {Tools.capitalize(t('action.back'))}
        </button>
        <div className="invitation-header">
          <ProTable
            headerTitle={TITLE_PRO_TABLE}
            rowKey="id"
            search={{
              resetText: Tools.capitalize(t('action.clean')),
              searchText: Tools.capitalize(t('action.search')),
              collapseRender: showCollapseRender(columns(false)),
              layout: 'vertical',
            }}
            request={async (params) => {
              setParams({ ...params });
              return [];
            }}
            columns={columns(false)}
            tableRender={() => <></>}
          />
        </div>
        <ProTable<IInvitation>
          onSizeChange={mobileOnSizeChangeProTable}
          components={{
            table: showComponent(),
          }}
          headerTitle={TITLE_PRO_TABLE}
          actionRef={actionRef}
          rowKey="id"
          search={false}
          onChange={(_, _filter, _sorter) => {
            const sorterResult = _sorter as SorterResult<IInvitation>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          onReset={() => {
            setSearchText('');
          }}
          params={{
            sorter,
          }}
          pagination={{
            showTotal: (total, [start, end]) =>
              `${start}-${end} ${t('protable.of')} ${total} ${t(
                'protable.invitations',
              )}`,
          }}
          toolBarRender={() => [
            <>
              <div className="content-search-table">
                <Tooltip
                  key="searchtext"
                  title={
                    Tools.capitalize(t('action.searchBy')) +
                    LIST_FILTER_NAMES.join(', ')
                  }
                >
                  <Input.Search
                    size="middle"
                    placeholder={INPUT_SEARCH_PLACEHOLDER}
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={(event) => {
                      setSearchText(event.target.value);
                    }}
                    allowClear={true}
                  />
                </Tooltip>
              </div>

              <>
                {Authorization.security(
                  functions,
                  EnumsValues.Functions.UserCreate,
                ) ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      setInvitationModalVisible(true);
                    }}
                    icon={<PlusOutlined />}
                  >
                    {Tools.capitalize(t('abm.invite'))}
                  </Button>
                ) : null}
              </>
              <>
                <ExporterDropdown exportable={exportableTable} />
              </>
            </>,
          ]}
          /**
           * @description este metodo debe poder ejecutar siempre la consulta al backend
           */
          request={async (params, sorter, filter) =>
            request({ ...params, sorter, filter })
          }
          columns={columns(false)}
        />
      </div>

      <SaveForm
        loading={formLoading}
        title={TITLE_INVITATION_FORM}
        onCancel={() => {
          setInvitationModalVisible(false);
        }}
        modalVisible={invitationModalVisible}
        onOk={(value) => createInvitation(value)}
        columns={invitationColumns()}
        values={{
          tenant_id: selectedTenantId,
        }}
        cancelText={Tools.capitalize(t('action.clean'))}
        submitText={Tools.capitalize(t('action.send'))}
      />
    </>
  );
};

export default AdminInvitationPage;
