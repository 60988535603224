export const WALLET_MOVEMENTS = `
query walletMovements(
  $tenant_id: Int!,
  $skip: Int,
  $take: Int,
  $orderBy: WalletMovementOrderInput,
  $filter: FilterWalletMovementInput,
  $searchText: String
){
  walletMovements(
    tenant_id:$tenant_id,
    skip:$skip,
    take:$take,
    orderBy:$orderBy,
    filter:$filter,
    searchText:$searchText
  ){
    __typename
    ...on WalletMovement{
      id
      amount
      description
      wallet_reason{
        name,
        translation_key
        type
        account_type
      }
      order_id
      order{
        id
        name
        shop{
          tenant_id,
          name
        }
      }
      its
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const WALLET_MOVEMENT_COUNT = `
query walletMovementCount(
  $tenant_id: Int!,
  $filter: FilterWalletMovementInput,
  $searchText: String
){
  walletMovementCount(
  	tenant_id:$tenant_id,
    filter:$filter,
    searchText:$searchText
  ){
    __typename
    ...on Count{
      count       
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
