/// <reference types="vite-plugin-svgr/client" />

import Icon from '@ant-design/icons';
import React from 'react';
import { ReactComponent as IconSvg } from '../../../assets/package_2.svg';

interface IPackage2IconProps extends React.ComponentProps<typeof Icon> {
  size?: number;
  color?: string;
}
export const Package2Icon: React.FC<IPackage2IconProps> = ({
  size,
  color,
  ...props
}) => {
  return (
    <Icon
      component={IconSvg}
      {...props}
      style={{
        fontSize: size,
        color: color,
        ...props.style,
      }}
    />
  );
};
