export const PRODUCTS = `
query products(
    $tenant_id: Int!,
    $skip:  Int,
    $take:  Int,
    $orderBy:  ProductOrderInput,
    $filter:  FilterProductInput,
    $searchText: String
  ){
  products(
    tenant_id:$tenant_id,
    skip: $skip,
    take: $take,
    orderBy: $orderBy,
    filter: $filter,
    searchText:$searchText
  ){
    __typename
    ...on Product{
      id
      name
      description
      cost
      suggested_price
      weight
      height
      width
      length
      status
      stock
      supplier_id
      supplier{
        id
        name
        fiscal_id_type
        fiscal_id
        product_address        
      }
      product_category{
        category_id                     
      }          
      has_variants,    
      product_specification{
        id
        code
        stock
        status
        suggested_price
        cost      
        product_picture{
          id
          url
        }
        option_1{
          id
          value
        }
        option_2{
          id
          value
        }
        option_3{
          id
          value
        }      
      } 
      warranty,
      is_private 
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const PRODUCT_COUNT = `
query productCount(
  $tenant_id: Int!, 
  $orderBy: ProductOrderInput, 
  $filter: FilterProductInput, 
  $searchText: String
){
productCount(
  tenant_id:$tenant_id, 
  orderBy:$orderBy, 
  filter:$filter, 
  searchText:$searchText
){
  __typename
  ...on Count{
    count      
  }
  ...on ResultError{
    status_code
    message
    message_translation_key
  }
}
}
`;

export const PRODUCT = `
query product(
  $tenant_id: Int!, 
  $id: Int!
){
product(
  tenant_id:$tenant_id, 
  id:$id
){
  __typename
  ...on Product{
    id
      name
      description
      cost
      suggested_price
      weight
      height
      width
      length
      status
      stock
      supplier_id
      supplier{
        id
        name
        fiscal_id_type
        fiscal_id
        product_address        
      }
      product_category{
        category_id                     
      }   
    has_variants,    
    product_specification{
      id
      code
      stock
      status
      suggested_price
      cost      
      product_picture{
        id
        url
      }
      option_1{
        id
        value
        product_variant_attribute{
          id
          name
        }
      }
      option_2{
        id
        value
        product_variant_attribute{
          id
          name
        }
      }
      option_3{
        id
        value
        product_variant_attribute{
          id
          name
        }
      }     
    }
      is_private
      warranty 
}
  ...on ResultError{
    status_code
    message
    message_translation_key
  }
}
}
`;
