export const SUPPLIERS = `
query suppliers(
  $tenant_id: Int!,
  $skip:  Int,
  $take:  Int,
  $orderBy: SupplierOrderInput,
  $filter: FilterSupplierInput,
  $searchText: String
){
  suppliers(
    tenant_id:$tenant_id,
    skip: $skip,
    take: $take,
    orderBy: $orderBy,
    filter: $filter,
    searchText:$searchText
  ){
    __typename
    ...on Supplier{
      id
      fiscal_id
      fiscal_id_type
      name
      product_address
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const SUPPLIER = `
query supplier(
    $id: Int!,  
){
  supplier(
    id:$id,  
  ){
    __typename
    ...on Supplier{
      id
      name
      fiscal_id_type
      fiscal_id
      product_address
      status
      logistic
      logistic_service
      client_id
      password
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
