import { IProduct } from './Product';

export interface ISupplier {
  id: number;
  name: string;
  fiscal_id_type: string;
  fiscal_id: string;
  product_address: string;
  status: number;
  logistic: string;
  logistic_service: string;
  client_id?: string;
  password?: string;
  product?: IProduct[];
}

export interface IUpdateSupplierForm {
  name: string;
  fiscal_id_type: string;
  fiscal_id: string;
  product_address: string;
  status: number;
  logistic: string;
  logistic_service: string;
  client_id?: string;
  password?: string;
}
export interface ICreateSupplierForm {
  name: string;
  fiscal_id_type: string;
  fiscal_id: string;
  product_address: string;
  status: number;
  logistic: string;
  logistic_service: string;
  client_id?: string;
  password?: string;
}
export const DEFAULT_ORDER_BY_SUPPLIERS = {
  direction: 'asc',
  field: 'name',
};
