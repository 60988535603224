import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend, {
  HttpBackendOptions,
  RequestCallback,
} from 'i18next-http-backend';
import localesHash from '../locales-hash.json';

export const defaultLanguage = import.meta.env.VITE_FALLBACK_LNG || 'es';
const debugMode = import.meta.env.VITE_I18N_DEBUG === 'TRUE';

const languageDetector = new LanguageDetector();
const detectLanguageFunction = languageDetector.detect;
languageDetector.detect = (detectionOrder) => {
  const detectedLanguage = detectLanguageFunction.call(
    languageDetector, // override internal this
    detectionOrder,
  );
  if (Array.isArray(detectedLanguage)) {
    return detectedLanguage.map((detection) => detection.split('-')[0]);
  } else {
    return detectedLanguage;
  }
};

const customTranslationFilesRequest: HttpBackendOptions['request'] = async (
  _options: HttpBackendOptions,
  url: string,
  _payload: {} | string,
  callback: RequestCallback,
): Promise<void> => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error(`Failed to load ${url}`);

    const data = await response.text();
    try {
      JSON.parse(data);
      callback(null, { data, status: response.status });
    } catch (jsonError) {
      console.warn(
        `Invalid JSON from primary request: ${url}. Attempting fallback...`,
      );
      throw new Error(`Invalid JSON response`);
    }
  } catch (error) {
    console.warn(`Primary load failed: ${url}. Attempting fallback...`);
    const fallbackUrl = url.replace(`.${localesHash.hash}`, '');
    try {
      const fallbackResponse = await fetch(fallbackUrl);
      if (!fallbackResponse.ok)
        throw new Error(`Fallback also failed: ${fallbackUrl}`);
      const fallbackData = await fallbackResponse.text();
      try {
        JSON.parse(fallbackData);
        callback(null, { data: fallbackData, status: fallbackResponse.status });
      } catch (jsonFallbackError) {
        console.error(
          `Fallback response is not valid JSON:`,
          jsonFallbackError,
        );
        callback(jsonFallbackError, { data: '', status: 500 });
      }
    } catch (fallbackError) {
      console.error(
        `Error loading both hash and fallback translations:`,
        fallbackError,
      );
      callback(fallbackError, { data: '', status: 500 });
    }
  }
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    fallbackLng: defaultLanguage,
    load: 'languageOnly',
    returnNull: true,
    debug: debugMode,
    backend: {
      loadPath:
        import.meta.env.VITE_ENV === 'dev'
          ? '/locales/{{lng}}/translations.json'
          : `/locales/{{lng}}/translations.${localesHash.hash}.json`,
      request: customTranslationFilesRequest as HttpBackendOptions['request'],
    },
  });

i18n.on('languageChanged', () => {});

export default i18n;
