/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Spin,
  Upload,
} from 'antd';
import ProductManagementSection from './ProductManagementSection/ProductManagementSection';
import './ProductManagement.less';
import { IFormCreateProduct } from '../../interfaces/Product';
import { FormInstance } from 'antd/lib/form';
import { useContext, useEffect, useRef, useState } from 'react';

import { ContextApp } from '../../contexts/ContextApp';
import { Tools } from '../../shared';
import { useForm } from 'antd/lib/form/Form';
import { EnumsValues } from '../../enums/EnumsValues';

import { AddPhotoAlternateIcon } from '../../components/common/icons/AddPhotoAlternateIcon';
import { UploadFile } from 'antd/lib/upload';
import { ArrowBackIosIcon } from '../../components/common/icons/ArrowBackIosIcon';
import { NavContext } from '@ionic/react';
import { useParams } from 'react-router';
import useProductStatus from '../../hooks/useProductStatus';
import { RadioButtonCard } from '../../components/common/RadioButtonCard/RadioButtonCard';
import useUploadImage from '../../hooks/useUploadImage';
import ModalPreviewImage from '../../components/common/ModalPreviewImage/ModalPreviewImage';
import useProduct from '../../hooks/useProduct';
import useCategory from '../../hooks/useCategory';
import useSupplier from '../../hooks/useSupplier';
import useProductsVariants from '../../hooks/useProductsVariants';
import ProductsVariantsContainer from './ProductsVariants/ProductsVariantsContainer/ProductsVariantsContainer';
import ProductsVariantsForm from './ProductsVariants/ProductsVariantsForm/ProductsVariantsForm';
import { CaretRightOutlined } from '@ant-design/icons';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import { IFormProductVariantAttributeOptionsCombinations } from '../../interfaces/ProductVariantAttributeOption';
import { PublicIcon } from '../../components/common/icons/PublicIcon';
import { LockIcon } from '../../components/common/icons/LockIcon';
import { ITenant } from '../../interfaces';

interface IProductManagementProps {
  editMode?: boolean;
}

const MAX_PRODUCTS_VARIANTS = 3;
const ProductManagement = ({ editMode }: IProductManagementProps) => {
  const { id } = useParams<any>();
  const { t, selectedTenantId, tenantsAssociatedToUser } =
    useContext(ContextApp);
  const { goBack, navigate } = useContext(NavContext);
  const [valueRadioTypeProductSelector, setValueRadioTypeProductSelector] =
    useState<any>(
      EnumsValues.RadioButtonSelectorProductTypesValues.UniqueProduct,
    );
  const [tenant, setTenant] = useState<ITenant>();
  const {
    closePreview,
    handleChange,
    handleRemove,
    loadingImage,
    openPreview,
    picturesToDelete,
    preview,
    fileList,
    customRequestValidations,
    handleChangeMultiple,
    handleRemoveMultiple,
    multipleFileList,
    addFilesToFileList,
    addFilesToMultipleFileList,
    multiplePicturesToDelete,
    resetStateMultipleFileList,
    resetStatePicturesToDelete,
    resetStateMultiplePicturesToDelete,
    resetStateFileList,
  } = useUploadImage();
  const {
    createProduct,
    getProduct,
    loadingCreateProduct,
    loadingProduct,
    product: productEditForm,
    loadingUpdateProduct,
    updateProduct,
    parseVariantsProductSpecifications,
    parseSingleProductSpecifications,
    getParsedInitialValuesToUpdate,
  } = useProduct({
    defaultValueStateLoadingProduct: !!editMode,
  });
  const {
    closeVariantConfigurationModalVisible,
    openVariantConfigurationModalVisible,
    variantConfigurationModalVisible,
    addForm,
    handleFieldChange,
    deleteForm,
    productVariantAttributesOptions,
    formIds,
    formsRefs,
    getOptionsCombinations,
    setProductVariantAttributesOptionsCombinations,
    productVariantAttributesOptionsCombinations,
  } = useProductsVariants();
  const { categories, getCategories, loadingCategories } = useCategory();
  const { getSuppliers, loadingSupplier, suppliers } = useSupplier();
  const { productStatuses } = useProductStatus();
  const [formInstance] = useForm();

  const dataFetchedRef = useRef<boolean>(false);
  const stickyContainerRef = useRef<HTMLDivElement>(null);

  const onChangeRadioTypeProductSelector = (e: RadioChangeEvent) =>
    setValueRadioTypeProductSelector(e.target.value);

  const onFinish = (
    values: IFormCreateProduct,
    formInstance: FormInstance<any>,
  ) => {
    if (editMode) {
      updateProduct({
        values,
        onSuccessCallback: () => {
          resetStateFileList();
          resetStatePicturesToDelete();
          resetStateMultipleFileList();
          resetStateMultiplePicturesToDelete();
          getProduct({ id });
        },
      });
    } else {
      createProduct({
        values,
        formInstance,
        onSuccessCallback: () => {
          navigate('/app/supplier/products');
        },
      });
    }
  };

  const getTenant = () => {
    if (tenantsAssociatedToUser && selectedTenantId) {
      const tenant = tenantsAssociatedToUser.find(
        (tenant) => tenant.id === selectedTenantId,
      );
      if (tenant) {
        setTenant(tenant);
      }
    }
  };

  const optionsSuppliers =
    tenant && tenant.supplier_account && tenant.supplier_account.length === 0
      ? suppliers.map((supplier) => ({
          label: supplier.name,
          value: supplier.id,
        }))
      : suppliers
          .filter(
            (supplier) =>
              tenant?.supplier_account?.[0]?.supplier_id === supplier.id,
          )
          .map((supplier) => ({
            label: supplier.name,
            value: supplier.id,
          }));

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getCategories();
    getSuppliers();
    getTenant();
  }, []);

  useEffect(() => {
    if (editMode && productEditForm) {
      if (productEditForm.has_variants) {
        if (
          productEditForm.product_specification &&
          productEditForm.product_specification.length
        ) {
          if (
            productEditForm.product_specification[0].product_picture?.length
          ) {
            addFilesToMultipleFileList(
              productEditForm.product_specification
                .map((ele) =>
                  ele.product_picture
                    ? {
                        entityId: ele.id,
                        files: ele.product_picture.map((ele: any) => ({
                          uid: ele.id,
                          name: ele.filename,
                          status: 'done' as UploadFileStatus,
                          url: ele.url,
                          older: true,
                        })),
                      }
                    : null,
                )
                .filter((ele) => ele !== null) as {
                entityId: number;
                files: (UploadFile & {
                  older?: boolean;
                })[];
              }[],
            );
          }
        }
      } else {
        if (
          productEditForm.product_specification &&
          productEditForm.product_specification.length
        ) {
          if (
            productEditForm.product_specification[0].product_picture?.length
          ) {
            addFilesToFileList(
              productEditForm.product_specification[0].product_picture.map(
                (ele: any) => ({
                  uid: ele.id,
                  name: ele.filename,
                  status: 'done',
                  url: ele.url,
                  older: true,
                }),
              ),
            );
          }
        }
      }
    }
  }, [productEditForm]);

  useEffect(() => {
    const mainScrollableDiv = document.getElementById('main-scrollable-div');

    const setClassNameScrolledToStickyContainer = () => {
      if (mainScrollableDiv) {
        if (mainScrollableDiv.scrollTop > 100) {
          if (stickyContainerRef.current)
            stickyContainerRef.current.classList.add(
              'product-management__container-buttons-return-save-scrolled',
            );
        } else {
          if (stickyContainerRef.current)
            stickyContainerRef.current.classList.remove(
              'product-management__container-buttons-return-save-scrolled',
            );
        }
      }
    };

    if (mainScrollableDiv)
      mainScrollableDiv.addEventListener(
        'scroll',
        setClassNameScrolledToStickyContainer,
      );

    return () => {
      const mainScrollableDiv = document.getElementById('main-scrollable-div');
      if (mainScrollableDiv)
        mainScrollableDiv.addEventListener(
          'scroll',
          setClassNameScrolledToStickyContainer,
        );
    };
  }, []);

  useEffect(() => {
    if (id && editMode) {
      getProduct({
        id,
        onErrorCallback: () => {
          navigate('/app/products');
        },
      });
    }
  }, []);

  useEffect(() => {
    if (editMode) {
      if (productEditForm) {
        if (productEditForm.has_variants) {
          setValueRadioTypeProductSelector(
            EnumsValues.RadioButtonSelectorProductTypesValues
              .ProductsWithVariants,
          );
          if (productEditForm.product_specification?.length) {
            setProductVariantAttributesOptionsCombinations([
              ...(productEditForm.product_specification.map((ele) => ({
                combination: [
                  ele.option_1 && ele.option_1.product_variant_attribute
                    ? {
                        attribute: ele.option_1.product_variant_attribute.name,
                        value: ele.option_1.value,
                      }
                    : null,
                  ele.option_2 && ele.option_2.product_variant_attribute
                    ? {
                        attribute: ele.option_2.product_variant_attribute.name,
                        value: ele.option_2.value,
                      }
                    : null,
                  ele.option_3 && ele.option_3.product_variant_attribute
                    ? {
                        attribute: ele.option_3.product_variant_attribute.name,
                        value: ele.option_3.value,
                      }
                    : null,
                ].filter((ele) => ele !== null),
                id: ele.id,
              })) as IFormProductVariantAttributeOptionsCombinations[]),
            ]);
          }
        }
      }
    }
  }, [productEditForm]);

  useEffect(() => {
    formInstance.validateFields(['button-configure-variants']);
  }, [productVariantAttributesOptionsCombinations]);

  useEffect(() => {
    formInstance.validateFields(
      productVariantAttributesOptionsCombinations.map(
        (ele) => `images-id${ele.id}`,
      ),
    );
  }, [multipleFileList]);

  let initialValues: any = {};
  if (editMode) {
    initialValues = getParsedInitialValuesToUpdate();
  } else {
    initialValues = {
      ...initialValues,
      supplier_id: optionsSuppliers[0]?.value,
      status: EnumsValues.ProductStatusId.Active,
    };
  }

  return (
    <div className="product-management">
      <div
        ref={stickyContainerRef}
        className="product-management__container-buttons-return-save"
      >
        <Button
          className="product-management__container-buttons-return-save__button-return"
          type="ghost"
          icon={<ArrowBackIosIcon />}
          onClick={() => {
            goBack();
          }}
        >
          {Tools.capitalize(t('action.back'))}
        </Button>
        <Button
          className="product-management__container-buttons-return-save__button-save"
          type="primary"
          onClick={() => {
            formInstance.submit();
          }}
          loading={loadingCreateProduct || loadingUpdateProduct}
        >
          {Tools.capitalize(t('action.save'))}
        </Button>
      </div>
      {loadingSupplier || loadingProduct || loadingCategories ? (
        <div className="product-management__container-loading">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Form
            layout="vertical"
            requiredMark="optional"
            initialValues={{
              ...initialValues,
            }}
            className="product-management__form"
            onFinish={async (values: any) => {
              let newMultipleImages: { [key: number]: UploadFile[] } = {};
              let newImages: UploadFile[] = [];
              if (editMode) {
                if (productEditForm?.has_variants) {
                  Object.keys(multipleFileList).forEach((ele: any) => {
                    newMultipleImages = {
                      ...newMultipleImages,
                      [ele]: multipleFileList[ele].filter((ele) => !ele.older),
                    };
                  });
                } else {
                  newImages = fileList.filter((ele) => !ele.older);
                }
              }

              const product_specifications =
                valueRadioTypeProductSelector ===
                  EnumsValues.RadioButtonSelectorProductTypesValues
                    .ProductsWithVariants &&
                productVariantAttributesOptionsCombinations.length
                  ? await parseVariantsProductSpecifications({
                      productVariantAttributesOptionsCombinations,
                      values,
                      editMode,
                      newMultipleImages,
                      multiplePicturesToDelete,
                    })
                  : await parseSingleProductSpecifications({
                      values,
                      editMode,
                      newImages,
                      picturesToDelete,
                    });

              values = {
                ...values,
                product_specifications,
                stock: !editMode ? product_specifications[0].stock : undefined,
                status: !editMode
                  ? EnumsValues.ProductStatusId.Active
                  : undefined,
                suggested_price: !editMode
                  ? product_specifications[0].suggested_price
                  : undefined,
                cost: !editMode ? product_specifications[0].cost : undefined,
              };

              if (
                valueRadioTypeProductSelector ===
                  EnumsValues.RadioButtonSelectorProductTypesValues
                    .ProductsWithVariants &&
                productVariantAttributesOptions.current.length &&
                !editMode
              ) {
                const product_options =
                  productVariantAttributesOptions.current.map((ele) => ({
                    ...ele.values,
                  }));
                values = { ...values, product_options };
              }
              onFinish(values, formInstance);
            }}
            form={formInstance}
          >
            <ProductManagementSection
              title={Tools.capitalize(
                t('label.productManagementSectionTitle.generalData'),
              )}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    label={Tools.capitalize(t('label.products.name'))}
                    rules={[
                      {
                        required: true,
                        message: Tools.capitalize(
                          t('validation.products.nameIsRequired'),
                        ),
                      },
                      {
                        max: EnumsValues.MaxLengthInputs.ProductName,
                        message: Tools.capitalize(
                          t('validation.products.productNameMaxChars', {
                            replace: {
                              max: EnumsValues.MaxLengthInputs.ProductName,
                            },
                          }),
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={Tools.capitalize(
                        t('placeholder.products.name'),
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label={Tools.capitalize(t('label.products.description'))}
                    rules={[
                      {
                        max: EnumsValues.MaxLengthInputs.ProductDescription,
                        message: Tools.capitalize(
                          t('validation.products.descriptionMaxChars', {
                            replace: {
                              max: EnumsValues.MaxLengthInputs
                                .ProductDescription,
                            },
                          }),
                        ),
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder={Tools.capitalize(
                        t('placeholder.products.description'),
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    name="categories"
                    label={Tools.capitalize(t('label.products.categories'))}
                    rules={[
                      {
                        required: true,
                        message: Tools.capitalize(
                          t('validation.products.categoriesIsRequired'),
                        ),
                      },
                    ]}
                  >
                    <Select
                      loading={loadingCategories}
                      options={categories.map((category) => ({
                        label: category.name,
                        value: category.id,
                      }))}
                      mode="multiple"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placeholder={Tools.capitalize(
                        t('placeholder.products.categories'),
                      )}
                      allowClear
                      showSearch
                      filterOption={(inputValue, option: any) =>
                        option?.label
                          ?.toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      }
                    />
                  </Form.Item>
                  {editMode ? (
                    <Form.Item
                      name="supplier_id"
                      label={Tools.capitalize(t('label.products.suppliers'))}
                      rules={[
                        {
                          required: true,
                          message: Tools.capitalize(
                            t('validation.products.suppliersIsRequired'),
                          ),
                        },
                      ]}
                    >
                      <Select
                        loading={loadingSupplier}
                        options={suppliers.map((supplier) => ({
                          label: supplier.name,
                          value: supplier.id,
                        }))}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        placeholder={Tools.capitalize(
                          t('placeholder.products.suppliers'),
                        )}
                        allowClear
                        showSearch
                        filterOption={(inputValue, option: any) =>
                          option?.label
                            ?.toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        }
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="supplier_id"
                      label={Tools.capitalize(t('label.products.suppliers'))}
                      rules={[
                        {
                          required: true,
                          message: Tools.capitalize(
                            t('validation.products.suppliersIsRequired'),
                          ),
                        },
                      ]}
                    >
                      <Select
                        loading={loadingSupplier}
                        options={optionsSuppliers}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        placeholder={Tools.capitalize(
                          t('placeholder.products.suppliers'),
                        )}
                        disabled={optionsSuppliers.length === 1}
                        allowClear
                        showSearch
                        filterOption={(inputValue, option: any) =>
                          option?.label
                            ?.toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        }
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="is_private"
                    label={Tools.capitalize(t('entity.visibility'))}
                    rules={[
                      {
                        required: true,
                        message: Tools.capitalize(
                          t('validation.products.visibilityIsRequired'),
                        ),
                      },
                    ]}
                  >
                    <Select
                      options={[
                        {
                          label: (
                            <>
                              <LockIcon style={{ marginRight: 8 }} />
                              {Tools.capitalize(t('entity.isPrivate'))}
                            </>
                          ),
                          value: true,
                        },
                        {
                          label: (
                            <>
                              <PublicIcon style={{ marginRight: 8 }} />
                              {Tools.capitalize(t('entity.isPublic'))}
                            </>
                          ),
                          value: false,
                        },
                      ]}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placeholder={Tools.capitalize(
                        t('placeholder.products.visibility'),
                      )}
                      allowClear
                      showSearch={false}
                      filterOption={(inputValue, option: any) =>
                        option?.label
                          ?.toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="warranty"
                    label={Tools.capitalize(t('entity.warranty'))}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder={Tools.capitalize(
                        t('placeholder.products.warranty'),
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ProductManagementSection>

            <ProductManagementSection
              title={Tools.capitalize(
                t('label.productManagementSectionTitle.measurementsData'),
              )}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="weight"
                    label={Tools.capitalize(t('label.products.weight'))}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value < 0) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.weightPositive'),
                        ),
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value >
                            EnumsValues.InputNumberMaxValues.DimensionsInputs
                          ) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.dimensionsMaxValue', {
                            replace: {
                              max: Tools.numberFormatter(
                                String(
                                  EnumsValues.InputNumberMaxValues
                                    .DimensionsInputs,
                                ),
                              ),
                            },
                          }),
                        ),
                      },
                      {
                        required: true,
                        message: Tools.capitalize(
                          t('validation.products.weightRequired'),
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={Tools.numberFormatter}
                      parser={Tools.numberParser}
                      placeholder={Tools.capitalize(
                        t('placeholder.products.weight'),
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    name="height"
                    label={Tools.capitalize(t('label.products.height'))}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value < 0) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.heightPositive'),
                        ),
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value >
                            EnumsValues.InputNumberMaxValues.DimensionsInputs
                          ) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.dimensionsMaxValue', {
                            replace: {
                              max: Tools.numberFormatter(
                                String(
                                  EnumsValues.InputNumberMaxValues
                                    .DimensionsInputs,
                                ),
                              ),
                            },
                          }),
                        ),
                      },
                      {
                        required: true,
                        message: Tools.capitalize(
                          t('validation.products.heightRequired'),
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={Tools.numberFormatter}
                      parser={Tools.numberParser}
                      placeholder={Tools.capitalize(
                        t('placeholder.products.height'),
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="width"
                    label={Tools.capitalize(t('label.products.width'))}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value < 0) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.widthPositive'),
                        ),
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value >
                            EnumsValues.InputNumberMaxValues.DimensionsInputs
                          ) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.dimensionsMaxValue', {
                            replace: {
                              max: Tools.numberFormatter(
                                String(
                                  EnumsValues.InputNumberMaxValues
                                    .DimensionsInputs,
                                ),
                              ),
                            },
                          }),
                        ),
                      },
                      {
                        required: true,
                        message: Tools.capitalize(
                          t('validation.products.widthRequired'),
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={Tools.numberFormatter}
                      parser={Tools.numberParser}
                      placeholder={Tools.capitalize(
                        t('placeholder.products.width'),
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    name="length"
                    label={Tools.capitalize(t('label.products.length'))}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value < 0) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.lengthPositive'),
                        ),
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value >
                            EnumsValues.InputNumberMaxValues.DimensionsInputs
                          ) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.dimensionsMaxValue', {
                            replace: {
                              max: Tools.numberFormatter(
                                String(
                                  EnumsValues.InputNumberMaxValues
                                    .DimensionsInputs,
                                ),
                              ),
                            },
                          }),
                        ),
                      },
                      {
                        required: true,
                        message: Tools.capitalize(
                          t('validation.products.lengthRequired'),
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={Tools.numberFormatter}
                      parser={Tools.numberParser}
                      placeholder={Tools.capitalize(
                        t('placeholder.products.length'),
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ProductManagementSection>

            <ProductManagementSection
              title={Tools.capitalize(
                t('label.productManagementSectionTitle.specificationsData'),
              )}
            >
              <Space direction="vertical" style={{ display: 'flex' }}>
                {!editMode && (
                  <>
                    <Radio.Group
                      style={{ width: '100%' }}
                      onChange={onChangeRadioTypeProductSelector}
                      value={valueRadioTypeProductSelector}
                    >
                      <Row gutter={[16, 16]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                          <RadioButtonCard
                            checked={
                              valueRadioTypeProductSelector ===
                              EnumsValues.RadioButtonSelectorProductTypesValues
                                .UniqueProduct
                            }
                            value={
                              EnumsValues.RadioButtonSelectorProductTypesValues
                                .UniqueProduct
                            }
                          >
                            <RadioButtonCard.Title>
                              {Tools.capitalize(
                                t('label.products.uniqueProductTitle'),
                              )}
                            </RadioButtonCard.Title>
                            <RadioButtonCard.Description>
                              {Tools.capitalize(
                                t('label.products.uniqueProductDescription'),
                              )}
                            </RadioButtonCard.Description>
                          </RadioButtonCard>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                          <RadioButtonCard
                            checked={
                              valueRadioTypeProductSelector ===
                              EnumsValues.RadioButtonSelectorProductTypesValues
                                .ProductsWithVariants
                            }
                            value={
                              EnumsValues.RadioButtonSelectorProductTypesValues
                                .ProductsWithVariants
                            }
                          >
                            <RadioButtonCard.Title>
                              {Tools.capitalize(
                                t('label.products.productWithVariantsTitle'),
                              )}
                            </RadioButtonCard.Title>
                            <RadioButtonCard.Description>
                              {Tools.capitalize(
                                t(
                                  'label.products.productWithVariantsDescription',
                                ),
                              )}
                            </RadioButtonCard.Description>
                          </RadioButtonCard>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </>
                )}
                {valueRadioTypeProductSelector ===
                EnumsValues.RadioButtonSelectorProductTypesValues
                  .UniqueProduct ? (
                  <>
                    <Row gutter={16}>
                      <Col span={12}>
                        {editMode &&
                        productEditForm?.product_specification?.length ? (
                          <>
                            <Form.Item name={`product_specification-id`} hidden>
                              <InputNumber
                                value={
                                  productEditForm.product_specification[0].id
                                }
                              />
                            </Form.Item>
                          </>
                        ) : null}
                        <Form.Item
                          name="cost"
                          label={Tools.capitalize(t('label.products.cost'))}
                          rules={[
                            {
                              required: true,
                              message: Tools.capitalize(
                                t('validation.products.costRequired'),
                              ),
                            },
                            {
                              validator: (_, value) => {
                                if (value < 0) {
                                  return Promise.reject();
                                }
                                return Promise.resolve();
                              },
                              message: Tools.capitalize(
                                t('validation.products.costPositive'),
                              ),
                            },
                            {
                              validator: (_, value) => {
                                const suggestedPrice = Number(
                                  formInstance?.getFieldValue(
                                    'suggested_price',
                                  ),
                                );
                                if (value > suggestedPrice) {
                                  return Promise.reject();
                                }
                                if (
                                  formInstance?.getFieldError('suggested_price')
                                    .length
                                ) {
                                  formInstance?.validateFields([
                                    'suggested_price',
                                  ]);
                                }
                                return Promise.resolve();
                              },
                              message: Tools.capitalize(
                                t(
                                  'validation.products.costMustBeMinorToSuggestedPrice',
                                ),
                              ),
                            },
                          ]}
                        >
                          <InputNumber
                            formatter={Tools.priceNumberFormatter}
                            parser={Tools.priceNumberParser}
                            placeholder={Tools.capitalize(
                              t('placeholder.products.cost'),
                            )}
                          />
                        </Form.Item>
                        <Form.Item
                          name="suggested_price"
                          label={Tools.capitalize(
                            t('label.products.suggestedPrice'),
                          )}
                          rules={[
                            {
                              required: true,
                              message: Tools.capitalize(
                                t('validation.products.suggestedPriceRequired'),
                              ),
                            },
                            {
                              validator: (_, value) => {
                                const cost = Number(
                                  formInstance?.getFieldValue('cost'),
                                );
                                if (!cost || value < cost) {
                                  return Promise.reject();
                                }
                                return Promise.resolve();
                              },
                              message: Tools.capitalize(
                                t(
                                  'validation.products.suggestedPriceGreaterThanCost',
                                ),
                              ),
                            },
                            {
                              validator: (_, value) => {
                                const cost = Number(
                                  formInstance?.getFieldValue('cost'),
                                );
                                if (value < cost) {
                                  return Promise.reject();
                                }
                                if (
                                  formInstance?.getFieldError('cost').length
                                ) {
                                  formInstance?.validateFields(['cost']);
                                }

                                return Promise.resolve();
                              },
                              message: Tools.capitalize(
                                t(
                                  'validation.products.suggestedPriceMustBeMajorToCostPrice',
                                ),
                              ),
                            },
                          ]}
                        >
                          <InputNumber
                            formatter={Tools.priceNumberFormatter}
                            parser={Tools.priceNumberParser}
                            placeholder={Tools.capitalize(
                              t('placeholder.products.suggestedPrice'),
                            )}
                          />
                        </Form.Item>
                        {!editMode && (
                          <>
                            <Form.Item
                              name="stock"
                              label={Tools.capitalize(
                                t('label.products.initialStock'),
                              )}
                              rules={[
                                {
                                  required: true,
                                  message: Tools.capitalize(
                                    t('validation.products.stockIsRequired'),
                                  ),
                                },
                                {
                                  validator: (_, value) => {
                                    if (value < 0) {
                                      return Promise.reject();
                                    }
                                    return Promise.resolve();
                                  },
                                  message: Tools.capitalize(
                                    t('validation.products.stockPositive'),
                                  ),
                                },
                              ]}
                            >
                              <InputNumber
                                formatter={Tools.numberFormatter}
                                parser={Tools.numberParser}
                                placeholder={Tools.capitalize(
                                  t('placeholder.products.stock'),
                                )}
                              />
                            </Form.Item>
                          </>
                        )}
                        <Form.Item
                          name="status"
                          label={Tools.capitalize(t('label.products.status'))}
                          rules={[
                            {
                              required: true,
                              message: Tools.capitalize(
                                t(
                                  'validation.products.productStatusIsRequired',
                                ),
                              ),
                            },
                          ]}
                        >
                          <Select
                            options={productStatuses.map((productStatus) => ({
                              label: productStatus.label,
                              value: productStatus.value,
                            }))}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            placeholder={Tools.capitalize(
                              t('placeholder.products.status'),
                            )}
                            allowClear
                            showSearch
                            filterOption={(inputValue, option: any) =>
                              option?.label
                                ?.toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="form-itemwithout-optional-mark"
                          name="images"
                          label={Tools.capitalize(t('label.products.images'))}
                          rules={[
                            {
                              validator: () => {
                                if (!fileList.length) {
                                  return Promise.reject();
                                }
                                return Promise.resolve();
                              },
                              message: Tools.capitalize(
                                t(
                                  'validation.products.productPictureIsRequired',
                                ),
                              ),
                            },
                          ]}
                        >
                          <Upload
                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={openPreview}
                            onRemove={handleRemove}
                            onChange={handleChange}
                            customRequest={customRequestValidations}
                          >
                            {fileList.length >= 3 ? null : (
                              <>
                                {loadingImage ? (
                                  <>
                                    <Spin size="small" />
                                  </>
                                ) : (
                                  <>
                                    <button
                                      style={{
                                        border: 0,
                                        background: 'none',
                                        color: 'black',
                                      }}
                                      type="button"
                                    >
                                      <AddPhotoAlternateIcon />
                                      <div style={{ marginTop: 8 }}>
                                        {Tools.capitalize(
                                          t(
                                            'placeholder.products.productPicture',
                                          ),
                                        )}
                                      </div>
                                    </button>
                                  </>
                                )}
                              </>
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    {!editMode && (
                      <>
                        <Form.Item
                          name={'button-configure-variants'}
                          rules={[
                            {
                              validator: () => {
                                if (
                                  valueRadioTypeProductSelector ===
                                    EnumsValues
                                      .RadioButtonSelectorProductTypesValues
                                      .ProductsWithVariants &&
                                  !productVariantAttributesOptionsCombinations.length
                                ) {
                                  return Promise.reject();
                                }
                                return Promise.resolve();
                              },
                              message: Tools.capitalize(
                                t(
                                  'validation.products.variantConfigurationIsRequired',
                                ),
                              ),
                            },
                          ]}
                        >
                          <Button
                            onClick={() =>
                              openVariantConfigurationModalVisible()
                            }
                          >
                            {Tools.capitalize(t('action.configureVariants'))}
                          </Button>
                        </Form.Item>
                      </>
                    )}
                    {productVariantAttributesOptionsCombinations.length ? (
                      <>
                        <span>
                          {productVariantAttributesOptionsCombinations[0].combination
                            .map((ele) => ele.attribute)
                            .join(' / ')}
                        </span>
                        <Collapse
                          style={{ backgroundColor: 'transparent' }}
                          bordered={false}
                          defaultActiveKey={productVariantAttributesOptionsCombinations.map(
                            (_, index) => String(index),
                          )}
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                          )}
                        >
                          {productVariantAttributesOptionsCombinations.map(
                            (ele, index) => (
                              <>
                                <Collapse.Panel
                                  key={index}
                                  header={ele.combination
                                    .map((ele) => ele.value)
                                    .join(' / ')}
                                  style={{
                                    marginBottom: '8px',
                                    backgroundColor: 'white',
                                    boxShadow: '0px 4px 20px 0px #0000001A',
                                    borderRadius: '16px',
                                  }}
                                >
                                  {editMode && (
                                    <>
                                      <Form.Item
                                        name={`product_specification-id${ele.id}`}
                                        hidden
                                      >
                                        <InputNumber value={ele.id} />
                                      </Form.Item>
                                    </>
                                  )}
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <Form.Item
                                        name={`cost-id${ele.id}`}
                                        label={Tools.capitalize(
                                          t('label.products.cost'),
                                        )}
                                        rules={[
                                          {
                                            required: true,
                                            message: Tools.capitalize(
                                              t(
                                                'validation.products.costRequired',
                                              ),
                                            ),
                                          },
                                          {
                                            validator: (_, value) => {
                                              if (value < 0) {
                                                return Promise.reject();
                                              }
                                              return Promise.resolve();
                                            },
                                            message: Tools.capitalize(
                                              t(
                                                'validation.products.costPositive',
                                              ),
                                            ),
                                          },
                                          {
                                            validator: (_, value) => {
                                              const suggestedPrice = Number(
                                                formInstance?.getFieldValue(
                                                  `suggested_price-id${ele.id}`,
                                                ),
                                              );

                                              if (value > suggestedPrice) {
                                                return Promise.reject();
                                              }
                                              if (
                                                formInstance?.getFieldError(
                                                  `suggested_price-id${ele.id}`,
                                                ).length
                                              ) {
                                                formInstance?.validateFields([
                                                  `suggested_price-id${ele.id}`,
                                                ]);
                                              }
                                              return Promise.resolve();
                                            },
                                            message: Tools.capitalize(
                                              t(
                                                'validation.products.costMustBeMinorToSuggestedPrice',
                                              ),
                                            ),
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          formatter={Tools.priceNumberFormatter}
                                          parser={Tools.priceNumberParser}
                                          placeholder={Tools.capitalize(
                                            t('placeholder.products.cost'),
                                          )}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        name={`suggested_price-id${ele.id}`}
                                        label={Tools.capitalize(
                                          t('label.products.suggestedPrice'),
                                        )}
                                        rules={[
                                          {
                                            required: true,
                                            message: Tools.capitalize(
                                              t(
                                                'validation.products.suggestedPriceRequired',
                                              ),
                                            ),
                                          },
                                          {
                                            validator: (_, value) => {
                                              const cost = Number(
                                                formInstance?.getFieldValue(
                                                  `cost-id${ele.id}`,
                                                ),
                                              );
                                              if (!cost || value < cost) {
                                                return Promise.reject();
                                              }
                                              return Promise.resolve();
                                            },
                                            message: Tools.capitalize(
                                              t(
                                                'validation.products.suggestedPriceGreaterThanCost',
                                              ),
                                            ),
                                          },
                                          {
                                            validator: (_, value) => {
                                              const cost = Number(
                                                formInstance?.getFieldValue(
                                                  `cost-id${ele.id}`,
                                                ),
                                              );
                                              if (value < cost) {
                                                return Promise.reject();
                                              }
                                              if (
                                                formInstance?.getFieldError(
                                                  `cost-id${ele.id}`,
                                                ).length
                                              ) {
                                                formInstance?.validateFields([
                                                  `cost-id${ele.id}`,
                                                ]);
                                              }

                                              return Promise.resolve();
                                            },
                                            message: Tools.capitalize(
                                              t(
                                                'validation.products.suggestedPriceMustBeMajorToCostPrice',
                                              ),
                                            ),
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          formatter={Tools.priceNumberFormatter}
                                          parser={Tools.priceNumberParser}
                                          placeholder={Tools.capitalize(
                                            t(
                                              'placeholder.products.suggestedPrice',
                                            ),
                                          )}
                                        />
                                      </Form.Item>
                                      {!editMode && (
                                        <>
                                          <Form.Item
                                            name={`stock-id${ele.id}`}
                                            label={Tools.capitalize(
                                              t('label.products.initialStock'),
                                            )}
                                            rules={[
                                              {
                                                required: true,
                                                message: Tools.capitalize(
                                                  t(
                                                    'validation.products.stockIsRequired',
                                                  ),
                                                ),
                                              },
                                              {
                                                validator: (_, value) => {
                                                  if (value < 0) {
                                                    return Promise.reject();
                                                  }
                                                  return Promise.resolve();
                                                },
                                                message: Tools.capitalize(
                                                  t(
                                                    'validation.products.stockPositive',
                                                  ),
                                                ),
                                              },
                                            ]}
                                          >
                                            <InputNumber
                                              formatter={Tools.numberFormatter}
                                              parser={Tools.numberParser}
                                              placeholder={Tools.capitalize(
                                                t('placeholder.products.stock'),
                                              )}
                                            />
                                          </Form.Item>
                                        </>
                                      )}
                                      <Form.Item
                                        initialValue={
                                          !editMode
                                            ? EnumsValues.ProductStatusId.Active
                                            : undefined
                                        }
                                        name={`status-id${ele.id}`}
                                        label={Tools.capitalize(
                                          t('label.products.status'),
                                        )}
                                        rules={[
                                          {
                                            required: true,
                                            message: Tools.capitalize(
                                              t(
                                                'validation.products.productStatusIsRequired',
                                              ),
                                            ),
                                          },
                                        ]}
                                      >
                                        <Select
                                          options={productStatuses.map(
                                            (productStatus) => ({
                                              label: productStatus.label,
                                              value: productStatus.value,
                                            }),
                                          )}
                                          getPopupContainer={(triggerNode) =>
                                            triggerNode.parentNode
                                          }
                                          placeholder={Tools.capitalize(
                                            t('placeholder.products.status'),
                                          )}
                                          allowClear
                                          showSearch
                                          filterOption={(
                                            inputValue,
                                            option: any,
                                          ) =>
                                            option?.label
                                              ?.toLowerCase()
                                              .indexOf(
                                                inputValue.toLowerCase(),
                                              ) >= 0
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        className="form-itemwithout-optional-mark"
                                        name={`images-id${ele.id}`}
                                        label={Tools.capitalize(
                                          t('label.products.images'),
                                        )}
                                        rules={[
                                          {
                                            validator: () => {
                                              let stateHasAtLeastOneImage =
                                                false;
                                              Object.keys(
                                                multipleFileList,
                                              ).forEach((ele: any) => {
                                                if (
                                                  multipleFileList[ele].length
                                                ) {
                                                  stateHasAtLeastOneImage =
                                                    true;
                                                }
                                              });
                                              if (!stateHasAtLeastOneImage) {
                                                return Promise.reject();
                                              }
                                              return Promise.resolve();
                                            },
                                            message: Tools.capitalize(
                                              t(
                                                'validation.products.atLeastOneImageIsRequired',
                                              ),
                                            ),
                                          },
                                        ]}
                                      >
                                        <Upload
                                          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                          listType="picture-card"
                                          fileList={
                                            multipleFileList
                                              ? multipleFileList[ele.id]
                                              : []
                                          }
                                          onPreview={openPreview}
                                          onRemove={(file) =>
                                            handleRemoveMultiple(file, ele.id)
                                          }
                                          onChange={(info) =>
                                            handleChangeMultiple(info, ele.id)
                                          }
                                          customRequest={
                                            customRequestValidations
                                          }
                                        >
                                          {(multipleFileList[ele.id] &&
                                            multipleFileList[ele.id].length) >=
                                          3 ? null : (
                                            <>
                                              {loadingImage ? (
                                                <>
                                                  <Spin size="small" />
                                                </>
                                              ) : (
                                                <>
                                                  <button
                                                    style={{
                                                      border: 0,
                                                      background: 'none',
                                                      color: 'black',
                                                    }}
                                                    type="button"
                                                  >
                                                    <AddPhotoAlternateIcon />
                                                    <div
                                                      style={{ marginTop: 8 }}
                                                    >
                                                      {Tools.capitalize(
                                                        t(
                                                          'placeholder.products.productPicture',
                                                        ),
                                                      )}
                                                    </div>
                                                  </button>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </Upload>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Collapse.Panel>
                              </>
                            ),
                          )}
                        </Collapse>
                      </>
                    ) : null}
                  </>
                )}
              </Space>
            </ProductManagementSection>
          </Form>
        </>
      )}
      <ModalPreviewImage preview={preview} onCancel={closePreview} />

      <Modal
        open={variantConfigurationModalVisible}
        onCancel={() => closeVariantConfigurationModalVisible()}
        title={Tools.capitalize(t('label.variantsConfigurationTitle'))}
        className="modal-product-variant"
        onOk={async () => {
          try {
            const validations = formsRefs.current.map((formRef) => {
              return formRef.current?.form.validateFields();
            });

            await Promise.all(validations);
            closeVariantConfigurationModalVisible();
            setProductVariantAttributesOptionsCombinations(
              getOptionsCombinations(productVariantAttributesOptions.current),
            );
          } catch (error) {
            //intentional
          }
        }}
      >
        {formIds.map((id, index) => (
          <>
            <ProductsVariantsContainer
              key={id}
              variant={index + 1}
              hideDeleteVariantButton={formIds.length === 1 && index === 0}
              onDeleteVariant={() => deleteForm(id)}
            >
              <ProductsVariantsForm
                onValuesChange={(_, values) => {
                  handleFieldChange(id, values);
                }}
                refForm={formsRefs.current[index]}
              />
            </ProductsVariantsContainer>
          </>
        ))}
        {formIds.length < MAX_PRODUCTS_VARIANTS && (
          <>
            <Button
              onClick={addForm}
              className="modal-product-variant__button-add-variant"
            >
              {Tools.capitalize(t('action.addOption'))}
            </Button>
          </>
        )}
      </Modal>
    </div>
  );
};
export default ProductManagement;
