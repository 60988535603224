import './ProductVariantsContainer.less';
import { useContext } from 'react';
import { Tools } from '../../../../shared';
import { ContextApp } from '../../../../contexts/ContextApp';
import { Button, Modal } from 'antd';
import { DeleteIcon } from '../../../../components/common/icons/DeleteIcon';

const ProductsVariantsContainer = ({
  variant,
  children,
  onDeleteVariant,
  hideDeleteVariantButton,
}: {
  variant: number;
  children?: JSX.Element | JSX.Element[];
  onDeleteVariant?: () => void;
  hideDeleteVariantButton?: boolean;
}) => {
  const { t } = useContext(ContextApp);

  const deleteVariantConfirm = () => {
    Modal.confirm({
      content: (
        <div>
          <span>{Tools.capitalize(t('label.deleteVariantConfirm'))}</span>
        </div>
      ),
      onOk: () => onDeleteVariant && onDeleteVariant(),
    });
  };

  return (
    <div className="products-variants-container">
      <div className="products-variants-container__container-title-button-delete">
        <span className="products-variants-container__container-title-button-delete__title">
          {Tools.capitalize(t('label.option'))} {variant}
        </span>
        {!hideDeleteVariantButton && (
          <>
            <Button
              onClick={deleteVariantConfirm}
              type="text"
              className="products-variants-container__container-title-button-delete__button-delete"
            >
              <DeleteIcon />
            </Button>
          </>
        )}
      </div>
      {children}
    </div>
  );
};
export default ProductsVariantsContainer;
