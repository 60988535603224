import {
  HomeOutlined,
  SettingOutlined,
  ControlOutlined,
  LockOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import DefaultRedirectPage from '../pages/DefaultRedirect/DefaultRedirectPage';
import { EnumsValues, PageKey } from '../enums/EnumsValues';
import { IRouteConfig } from '../interfaces/routeConfig';
import { Suspense, lazy } from 'react';
import { PageLoading } from '@ant-design/pro-layout';
import AdminInvitationPage from '../pages/AdminInvitation/AdminInvitationPage';
import InvitationPage from '../pages/Invitation/Invitation';
import { Redirect } from 'react-router';
import TenantSettingPage from '../pages/TenantSetting/TenantSettingPage';
import EmptyLayout from '../layouts/emptyLayout/index';
import { InventaryIcon } from '../components/common/icons/InventaryIcon';
import { LocationHomeIcon } from '../components/common/icons/LocationHomeIcon';
import { Package2Icon } from '../components/common/icons/Package2Icon';
import { PowerIcon } from '../components/common/icons/PowerIcon';
import { AccountBalanceWalletIcon } from '../components/common/icons/AccountBalanceWalletIcon';
import ProductManagement from '../pages/ProductManagement/ProductManagement';
import { StoreIcon } from '../components/common/icons/StoreIcon';

const HomePage = lazy(() => import('../pages/Home/HomePage'));
const LoginPage = lazy(() => import('../pages/Login/LoginPage'));
const PublicLayout = lazy(() => import('../layouts/publicLayout'));
const RegisteredLayout = lazy(() => import('../layouts/registered'));
const AppSettingPage = lazy(() => import('../pages/AppSetting/AppSettingPage'));
const ResetPasswordContentPage = lazy(
  () => import('../pages/ResetPassword/ResetPasswordContentPage'),
);
const SetPasswordContentPage = lazy(
  () => import('../pages/SetPassword/SetPasswordPage'),
);
const ProfilePage = lazy(() => import('../pages/Profile/ProfilePage'));
const RolePage = lazy(() => import('../pages/Role/RolePage'));
const PermissionPage = lazy(() => import('../pages/Permission/PermissionPage'));
const UserPage = lazy(() => import('../pages/User/UserPage'));
const AboutPage = lazy(() => import('../pages/About/AboutPage'));
const Reminder2FA = lazy(
  () => import('../pages/Totp2FA/Reminder2FA/Reminder2FA'),
);
const Generate2FA = lazy(
  () => import('../pages/Totp2FA/Generate2FA/Generate2FA'),
);
const Validate2FA = lazy(
  () => import('../pages/Totp2FA/Validate2FA/Validate2FA'),
);
const Config2FA = lazy(() => import('../pages/Totp2FA/Config2FA/Config2FA'));
const TenantPage = lazy(() => import('../pages/Tenant/TenantPage'));
const CompleteRegisterPage = lazy(
  () => import('../pages/CompleteRegister/CompleteRegisterPage'),
);
const RegisterPage = lazy(() => import('../pages/Register/RegisterPage'));
const MaintenancePage = lazy(
  () => import('../pages/Maintenance/MaintenancePage'),
);
const StatusPage = lazy(() => import('../pages/Status/StatusPage'));
const TermsAndConditionsPage = lazy(
  () => import('../pages/TermsAndConditions/TermsAndConditionsPage'),
);
const ManageTermsAndConditionsPage = lazy(
  () =>
    import('../pages/ManageTermsAndConditions/ManageTermsAndConditionsPage'),
);
const SelectTenantPage = lazy(
  () => import('../pages/SelectTenantPage/SelectTenant'),
);
const ProductsPage = lazy(() => import('../pages/Products/ProductsPage'));

const CategoriesPage = lazy(() => import('../pages/Categories/CategoriesPage'));
const ProductHistoryPage = lazy(
  () => import('../pages/ProductHistory/ProductHistoryPage'),
);

const MyAccountPage = lazy(() => import('../pages/MyAccount/MyAccountPage'));

const ConnectionsPage = lazy(
  () => import('../pages/Connections/ConnectionsPage'),
);

const OrdersPage = lazy(() => import('../pages/Orders/OrdersPage'));

const OrderDetailPage = lazy(
  () => import('../pages/OrderDetail/OrderDetailPage'),
);
const SuppliersPage = lazy(() => import('../pages/Suppliers/SuppliersPage'));
const WalletPage = lazy(() => import('../pages/Wallet/WalletPage'));
const SupplierProductsPage = lazy(
  () => import('../pages/SupplierMenu/SupplierProducts/SupplierProductsPage'),
);
const SupplierManagementPage = lazy(
  () => import('../pages/SupplierManagement/SupplierManagementPage'),
);

const redirectIfNotExist = {
  path: '*',
  component: () => <Redirect to="/" />,
  key: PageKey.RedirectIfNotExist,
  hideInMenu: true,
};

const routes: IRouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: DefaultRedirectPage,
    key: PageKey.DefaultRedirect,
  },
  {
    path: '/app',
    component: (props: any) => (
      <Suspense fallback={<PageLoading />}>
        <RegisteredLayout {...props} />
      </Suspense>
    ),
    key: PageKey.App,
    routes: [
      {
        path: '/app/home',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <HomePage />
          </Suspense>
        ),
        name: 'Home',
        translation_key: 'pages.home',
        icon: <HomeOutlined />,
        key: PageKey.Home,
        requiresTenantSelected: true,
      },
      {
        path: '/app/products',
        authority: EnumsValues.Functions.ProductRead,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <ProductsPage />
          </Suspense>
        ),
        name: 'Productos',
        translation_key: 'pages.products',
        icon: <InventaryIcon />,
        key: PageKey.Products,
      },
      {
        path: '/app/newProduct',
        authority: EnumsValues.Functions.ProductCreate,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <ProductManagement />
          </Suspense>
        ),
        name: 'Crear producto',
        translation_key: 'pages.newProduct',
        key: PageKey.NewProduct,
        hideInMenu: true,
      },
      {
        path: '/app/updateProduct/:id',
        authority: EnumsValues.Functions.ProductUpdate,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <ProductManagement editMode={true} />
          </Suspense>
        ),
        name: 'Actualizar producto',
        translation_key: 'pages.updateProduct',
        key: PageKey.UpdateProduct,
        hideInMenu: true,
      },
      {
        path: '/app/productHistory/:id',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <ProductHistoryPage />
          </Suspense>
        ),
        translation_key: 'pages.productHistory',
        hideInMenu: true,
        key: PageKey.ProductHistory,
      },
      {
        path: '/app/orders',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <OrdersPage mode={EnumsValues.OrdersPageMode.Dropshipper} />
          </Suspense>
        ),
        authority: EnumsValues.Functions.OrderRead,
        name: 'Pedidos',
        icon: <Package2Icon />,
        translation_key: 'pages.orders',
        key: PageKey.Orders,
        requiresTenantSelected: true,
      },
      {
        path: '/app/orderDetail/:id',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <OrderDetailPage
              mode={EnumsValues.OrderDetailPageMode.Dropshipper}
            />
          </Suspense>
        ),
        authority: EnumsValues.Functions.OrderRead,
        translation_key: 'pages.orderDetail',
        key: PageKey.Orders,
        hideInMenu: true,
        requiresTenantSelected: true,
      },
      {
        path: '/app/connections',
        authority: EnumsValues.Functions.ShopRead,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <ConnectionsPage />
          </Suspense>
        ),
        icon: <PowerIcon />,
        name: 'Conexiones',
        translation_key: 'pages.connections',
        key: PageKey.Connections,
        isStorablePath: {
          storable: true,
          requiredSearchParamsToStoreUrl: ['connectShopId'],
        },
      },
      {
        path: '/app/wallet',
        authority: EnumsValues.Functions.WalletMovementRead,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <WalletPage />
          </Suspense>
        ),
        icon: <AccountBalanceWalletIcon />,
        name: 'Billetera',
        translation_key: 'pages.wallet',
        key: PageKey.Wallet,
      },
      {
        path: '/app/supplier',
        name: 'Proveedor',
        translation_key: 'pages.supplier',
        component: EmptyLayout,
        icon: <StoreIcon />,
        key: PageKey.Supplier,
        routes: [
          {
            path: '/app/supplier/products',
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <SupplierProductsPage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.ProductRead,
            name: 'Productos',
            translation_key: 'pages.products',
            key: PageKey.SupplierProducts,
            requiresTenantSelected: true,
          },
          {
            path: '/app/supplier/orders',
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <OrdersPage mode={EnumsValues.OrdersPageMode.Supplier} />
              </Suspense>
            ),
            authority: EnumsValues.Functions.OrderRead,
            name: 'Pedidos',
            translation_key: 'pages.orders',
            backgroundColor: '#EBF9FF',
            key: PageKey.SupplierOrders,
            requiresTenantSelected: true,
          },
          {
            path: '/app/supplier/orderDetail/:id',
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <OrderDetailPage mode={EnumsValues.OrdersPageMode.Supplier} />
              </Suspense>
            ),
            hideInMenu: true,
            authority: EnumsValues.Functions.OrderRead,
            translation_key: 'pages.orderDetail',
            backgroundColor: '#EBF9FF',
            key: PageKey.SupplierOrderDetail,
            requiresTenantSelected: true,
          },
        ],
      },
      {
        path: '/app/myAccount',
        authority: EnumsValues.Functions.TenantRead,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <MyAccountPage />
          </Suspense>
        ),
        name: 'Mi cuenta',
        translation_key: 'pages.myAccount',
        icon: <LocationHomeIcon />,
        key: PageKey.MyAccount,
      },
      {
        path: '/app/config2fa',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <Config2FA />
          </Suspense>
        ),
        hideInMenu: true,
        name: 'Autenticación 2FA',
        translation_key: 'pages.auth2FA',
        icon: <LockOutlined />,
        key: PageKey.Config2fa,
        requiresTenantSelected: true,
      },
      {
        path: '/app/administration',
        name: 'Administración',
        translation_key: 'pages.administration',
        component: EmptyLayout,
        icon: <ControlOutlined />,
        authority: EnumsValues.Functions.AdministrationMenuRead,
        key: PageKey.Administration,
        hideInMobileMenu: true,
        routes: [
          {
            path: '/app/administration/role',
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <RolePage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserRoleList,
            name: 'Roles',
            translation_key: 'pages.roles',
            key: PageKey.Role,
            requiresTenantSelected: true,
          },
          {
            path: '/app/administration/permission',
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <PermissionPage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.PermissionList,
            name: 'Permisos',
            translation_key: 'pages.permissions',
            key: PageKey.Permission,
            requiresTenantSelected: true,
          },
          {
            path: '/app/administration/tenant',
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <TenantPage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.TenantRead,
            name: 'Tenants',
            translation_key: 'pages.ecomdropAccounts',
            key: PageKey.Tenant,
            hideInMenu: (_data, IsAppMultitenant, isAdmin) => {
              return !(IsAppMultitenant || isAdmin);
            },
            requiresTenantSelected: true,
          },
          {
            path: '/app/administration/suppliers',
            authority: EnumsValues.Functions.SupplierRead,
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <SuppliersPage />
              </Suspense>
            ),
            name: 'Proveedores',
            translation_key: 'pages.suppliers',
            key: PageKey.Suppliers,
          },
          {
            path: '/app/administration/newSupplier',
            authority: EnumsValues.Functions.SupplierCreate,
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <SupplierManagementPage />
              </Suspense>
            ),
            name: 'Nuevo proveedor',
            hideInMenu: true,
            translation_key: 'pages.newSupplier',
            key: PageKey.NewSupplier,
          },
          {
            path: '/app/administration/updateSupplier/:id',
            authority: EnumsValues.Functions.SupplierUpdate,
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <SupplierManagementPage editMode />
              </Suspense>
            ),
            name: 'Actualizar proveedor',
            hideInMenu: true,
            translation_key: 'pages.updateSupplier',
            key: PageKey.UpdateSupplier,
          },
          {
            path: '/app/administration/user',
            component: (props: any) => (
              <Suspense fallback={<PageLoading />}>
                <UserPage {...props} />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserRoleList,
            name: 'Usuarios',
            translation_key: 'pages.users',
            key: PageKey.User,
            requiresTenantSelected: true,
          },
          {
            path: '/app/administration/termsAndConditions',
            component: (props: any) => (
              <Suspense fallback={<PageLoading />}>
                <ManageTermsAndConditionsPage {...props} />
              </Suspense>
            ),
            authority: EnumsValues.Functions.TermsAndConditionsUpdate,
            name: 'Términos y condiciones',
            translation_key: 'pages.termsAndConditions',
            key: PageKey.ManageTermsAndConditions,
            requiresTenantSelected: true,
          },
        ],
      },
      {
        path: '/app/productAdministration',
        name: 'Menú Productos',
        translation_key: 'pages.productAdministration',
        component: EmptyLayout,
        icon: <ControlOutlined />,
        authority: EnumsValues.Functions.ProductAdministrationMenuRead,
        key: PageKey.ProductAdministration,
        hideInMobileMenu: true,
        routes: [
          {
            path: '/app/productAdministration/categories',
            component: (props: any) => (
              <Suspense fallback={<PageLoading />}>
                <CategoriesPage {...props} />
              </Suspense>
            ),
            authority: EnumsValues.Functions.CategoryRead,
            name: 'Categorías',
            translation_key: 'pages.categories',
            key: PageKey.Categories,
            requiresTenantSelected: true,
          },
        ],
      },
      {
        path: '/app/accountAdministration',
        name: 'Cuenta',
        translation_key: 'pages.accountAdministration',
        component: EmptyLayout,
        icon: <ControlOutlined />,
        authority: EnumsValues.Functions.AccountAdministrationMenuRead,
        key: PageKey.AccountAdministration,
        hideInMobileMenu: true,
        routes: [
          {
            path: '/app/accountAdministration/tenant-setting',
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <TenantSettingPage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.ReadTenantSetting,
            name: 'Configuración',
            translation_key: 'pages.ecomdropAccountConfiguration',
            key: PageKey.TenantSetting,
            hideInMenu: (_data, IsAppMultitenant, isAdmin) => {
              return !(IsAppMultitenant || isAdmin);
            },
            requiresTenantSelected: true,
          },
          {
            path: '/app/accountAdministration/user',
            component: (props: any) => (
              <Suspense fallback={<PageLoading />}>
                <UserPage {...props} />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserRoleList,
            name: 'Usuarios',
            translation_key: 'pages.users',
            key: PageKey.User,
            requiresTenantSelected: true,
          },
        ],
      },
      {
        path: '/app/settingAdministration',
        name: 'Configuraciónes',
        translation_key: 'pages.settingAdministration',
        component: EmptyLayout,
        icon: <SettingOutlined />,
        authority: EnumsValues.Functions.SettingAdministrationMenuRead,
        key: PageKey.SettingAdminstration,
        hideInMobileMenu: true,
        routes: [
          {
            path: '/app/settingAdministration/setting',
            authority: EnumsValues.Functions.AppSettingRead,
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <AppSettingPage />
              </Suspense>
            ),
            name: 'Generales',
            translation_key: 'pages.configuration',
            key: PageKey.Setting,
          },
        ],
      },
      {
        path: '/app/invitations',
        component: (props: any) => (
          <Suspense fallback={<PageLoading />}>
            <AdminInvitationPage {...props} />
          </Suspense>
        ),
        authority: EnumsValues.Functions.UserCreate,
        hideInMenu: true,
        name: 'Invitaciones',
        translation_key: 'pages.invitations',
        key: PageKey.Invitations,
        requiresTenantSelected: true,
      },
      {
        path: '/app/termsAndConditions',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <TermsAndConditionsPage mode="registered" />
          </Suspense>
        ),
        name: 'Términos y condiciones',
        translation_key: 'pages.termsAndConditions',
        icon: <FileTextOutlined />,
        key: PageKey.TermsAndConditions,
      },
      {
        path: '/app/about',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <AboutPage />
          </Suspense>
        ),
        name: 'Acerca de',
        translation_key: 'pages.about',
        icon: <BulbOutlined />,
        key: PageKey.About,
      },
      {
        path: '/app/selectTenant',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <SelectTenantPage />
          </Suspense>
        ),
        hideInMenu: true,
        key: PageKey.SelectTenant,
      },
      {
        path: '/app/profile',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <ProfilePage />
          </Suspense>
        ),
        name: 'Mi perfil',
        translation_key: 'pages.myProfile',
        hideInMenu: true,
        key: PageKey.Profile,
      },
      {
        path: '/app/status',
        authority: EnumsValues.Functions.IsAdmin,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <StatusPage />
          </Suspense>
        ),
        name: 'Status info',
        translation_key: 'pages.statusInfo',
        icon: <InfoCircleOutlined />,
        key: PageKey.Status,
      },
      redirectIfNotExist,
    ],
  },
  {
    path: '/',
    component: (props: any) => (
      <Suspense fallback={<PageLoading />}>
        <PublicLayout {...props} />
      </Suspense>
    ),
    key: PageKey.PublicLayout,
    routes: [
      {
        path: '/resetPassword',
        exact: true,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <ResetPasswordContentPage />
          </Suspense>
        ),
        key: PageKey.ResetPassword,
      },
      {
        exact: true,
        path: '/setPassword',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <SetPasswordContentPage />
          </Suspense>
        ),
        key: PageKey.SetPassword,
      },
      {
        exact: true,
        path: '/completeRegister',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <CompleteRegisterPage />
          </Suspense>
        ),
        key: PageKey.CompleteRegister,
      },
      {
        exact: true,
        path: '/termsAndConditions',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <TermsAndConditionsPage mode="public" />
          </Suspense>
        ),
        key: PageKey.TermsAndConditions,
      },
      {
        path: '/login',
        exact: true,
        component: (props: any) => (
          <Suspense fallback={<PageLoading />}>
            <LoginPage {...props} />
          </Suspense>
        ),
        key: PageKey.Login,
      },
      {
        path: '/register',
        exact: true,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <RegisterPage />
          </Suspense>
        ),
        key: PageKey.Register,
      },
      {
        path: '/reminder',
        exact: true,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <Reminder2FA />
          </Suspense>
        ),
        key: PageKey.Reminder,
      },
      {
        path: '/generate',
        exact: true,
        component: (props: any) => (
          <Suspense fallback={<PageLoading />}>
            <Generate2FA {...props} />
          </Suspense>
        ),
        key: PageKey.Generate,
      },
      {
        path: '/validateTotp',
        exact: true,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <Validate2FA />
          </Suspense>
        ),
        key: PageKey.ValidateTotp,
      },
      {
        path: '/invitation',
        exact: true,
        component: (props: any) => (
          <Suspense fallback={<PageLoading />}>
            <InvitationPage {...props} />
          </Suspense>
        ),
        translation_key: 'pages.invitation',
        key: PageKey.Invitation,
      },
      {
        path: '/maintenance',
        exact: true,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <MaintenancePage />
          </Suspense>
        ),
        name: 'En mantenimiento',
        translation_key: 'pages.inMaintenance',
        hideInMenu: true,
        key: PageKey.Maintenance,
      },
      redirectIfNotExist,
    ],
  },
];

export default routes;
