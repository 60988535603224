export const ORDER_COUNT = `
query orderCount(
    $tenant_id: Int!,     
    $orderBy: OrderOrderInput,
    $filter: FilterOrderInput, 
    $searchText: String
  ){
  orderCount(
    tenant_id:$tenant_id, 
    orderBy:$orderBy,
    filter:$filter, 
    searchText:$searchText
  ){
    __typename
    ...on Count{
      count
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const ORDERS = `
query orders(
  $tenant_id: Int!, 
  $skip: Int, 
  $take: Int, 
  $orderBy: OrderOrderInput,
  $filter: FilterOrderInput, 
  $searchText: String
  $supplier_id: Int
){
orders(
  tenant_id:$tenant_id, 
  skip:$skip, 
  take:$take, 
  orderBy:$orderBy,
  filter:$filter, 
  searchText:$searchText,
  supplier_id:$supplier_id
){
  __typename
  ...on Order{
    id
    shop_id
    tenant_id
    name
    tag_entity {
    id
        tag {
            id
						name
						entity
						hex_color
            account_type
            is_administration_tag
        }
    }
    shop{
      id
      tenant_id
      external_shop_id
      name
      url
      active
      connection_status
      token_created_at
    }
    order_status{
      id
      name
      translation_key
      position
      hex_color
      requires_payment   
    }
    external_order_id
    payment_type
    customer_name
    customer_email
    customer_phone
    latitude
    longitude
    address_line_1
    address_line_2
    zip_code
    city
    province
    country
    note
    external_date
    transport_company
    commission_percentage
    shipping_price
    total
    order_line{
      id
      product_specification{
        id
        code
        product{
          id
          name
          description
          weight
          height
          width
          length
          supplier_id
          supplier{
            id
            name
          }
          product_category{
            category{
              id
              name
            }
          }
        }
        product_picture{
          id
          filename
          url
        }       
        cost           
        suggested_price
        status
        stock        
      }
      sku
      name
      amount
      unit_price
      its      
    }    
    tracking_code
    paid
    ticket_status
    uts
    ticket_printed
    payment_reception
    supplier_commission
  }
  ...on ResultError{
    status_code
    message
    message_translation_key
  }
}
}
`;

export const ORDER = `
query order(
  $id: Int!, 
  $tenant_id: Int!
  $supplier_id: Int
){
order(
  id: $id, 
  tenant_id:$tenant_id
  supplier_id:$supplier_id
){
  __typename
  ...on Order{
    id
    shop_id
    tenant_id
    name
    tag_entity {
    id
        tag {
            id
						name
						entity
						hex_color
        }
    }
    shop{
      id
      tenant_id
      external_shop_id
      name
      url
      active
      connection_status
      token_created_at
    }
    order_status{
      id
      name
      translation_key
      position
      hex_color
      requires_payment
    }
    external_order_id
    payment_type
    customer_name
    customer_email
    customer_phone
    latitude
    longitude
    address_line_1
    address_line_2
    zip_code
    city
    province
    country
    note
    external_date
    transport_company
    commission_percentage
    shipping_price
    total
    order_line{
      id
      product_specification{
        id
        code
        option_1{
          value
          product_variant_attribute{
            name
          }
        }
        option_2{
          value
          product_variant_attribute{
            name
          }
        }
        option_3{
          value 
          product_variant_attribute{
            name
          }
        }
        product{
          id
          has_variants
          name
          description
          weight
          height
          width
          length
          supplier_id
          supplier{
            id
            name
          }
          product_category{
            category{
              id
              name
            }
          }          
        }
        product_picture{
          id
          filename
          url
        }        
        cost           
        suggested_price        
        status
        stock        
      }
      sku
      name
      amount
      unit_price
      its
      cost
    }    
    tracking_code  
    paid
    ticket_status
    uts
    ticket_printed
    payment_reception
    payment_detail
    cod_commission_percentage
    ticket_file {
      url
    }
    supplier_commission
  }
  ...on ResultError{
    status_code
    message
    message_translation_key
  }
}
}
`;

export const AVAILABLE_ORDER_ACTIONS = `
query availableOrderActions(
  $tenant_id: Int!
  $input: AvailableActionsInput!
){
  availableOrderActions(
    tenant_id:$tenant_id
    input:$input
  ){
    __typename
    ...on OrderAction{
      id
      name
      is_main_action
      translation_key
      level
      multiple
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }  
}
`;
